import {Controller} from "@hotwired/stimulus"
import {FetchRequest, post} from "@rails/request.js"

// Connects to data-controller="inspections"
export default class extends Controller {
  connect() {
    this.token = document.querySelector('meta[name="csrf-token"]').content;
  }

  disconnect() {
    super.disconnect()
  }

  async consolidate(event) {
    const {params} = event
    const {inspectionId, locale} = params
    const response = await post(`/${locale}/inspections/${inspectionId}/consolidate`, {responseKind: 'turbo-stream'})
    const { text, ok } = await response
  }

  toggleNorm(event) {
    event.preventDefault()

    const {nextElementSibling, checked} = event.target
    const {lastElementChild} = nextElementSibling

    if (checked) {
      lastElementChild.classList.remove("bi-x", "text-danger")
      lastElementChild.classList.add("bi-check", "text-success")
    } else {
      lastElementChild.classList.remove("bi-check", "text-success")
      lastElementChild.classList.add("bi-x", "text-danger")
    }
  }

  toggleRow(event) {
    event.preventDefault()

    const {checked} = event.target
    const elements = this.element.parentElement.querySelectorAll('.inspections-group-entity-checkbox')

    if (elements.length) {
      elements.forEach((element) => {
        element.disabled = !checked
      })
    }
  }

  toggleColumn(event) {
    event.preventDefault()

    const {params} = event
    const {checked} = event.target
    const elements = document.querySelectorAll(`input.${params.elementId}`)

    if (elements.length) {
      elements.forEach((element) => {
        // if (!element.disabled) element.checked = checked
        const changeEvent = new Event('change')
        element.checked = checked
        element.dispatchEvent(changeEvent)
      })
    }
  }

  toggleCaret(event) {
    event.preventDefault()
    const {target} = event
    const {lastElementChild} = target
    if (lastElementChild) {
      if (lastElementChild.classList.contains("bi-caret-right-fill") || lastElementChild.classList.contains("bi-caret-down-fill")) {
        lastElementChild.classList.toggle("bi-caret-right-fill")
        lastElementChild.classList.toggle("bi-caret-down-fill")
      } else {
        lastElementChild.classList.toggle("bi-caret-right")
        lastElementChild.classList.toggle("bi-caret-down")
      }
    } else {
      if (target.classList.contains("bi-caret-right-fill") || target.classList.contains("bi-caret-down-fill")) {
        target.classList.toggle("bi-caret-right-fill")
        target.classList.toggle("bi-caret-down-fill")
      } else {
        target.classList.toggle("bi-caret-right")
        target.classList.toggle("bi-caret-down")
      }
    }
  }
}
